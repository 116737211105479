import styled from "styled-components"

export const Line = styled.div`
  &:nth-child(odd) {
    background: #ddd;
  }
`

export const ProductsWrapper = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  flex-wrap: wrap;
`

export const ProductWrapper = styled.div`
  width: 20%;
  margin: 10px 2% 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    margin: auto auto 0;
  }

  &.pote-500 {
    .gatsby-image-wrapper {
      max-width: 230px;
      width: 100%;
    }
  }

  &.pote-granola {
    .gatsby-image-wrapper {
      max-width: 170px;
      width: 100%;
    }
  }

  &.pote-grande {
    .gatsby-image-wrapper {
      max-width: 222px;
      width: 100%;
    }
  }

  &.polpa-grande {
    .gatsby-image-wrapper {
      max-width: 150px;
      width: 100%;
    }
  }

  &.polpa-pequena {
    .gatsby-image-wrapper {
      max-width: 108px;
      width: 100%;
    }
  }

  @media (max-width: 900px) {
    width: 40%;
  }

  h3 {
    font-family: zonaproblack;
    color: #551a8b;
    margin-top: 10px;
  }

  h4 {
    margin-top: 4px;
    color: #551a8b;
  }
`
