import React, { useContext } from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Header from "../components/Header"
import Divider from "../components/Divider"
import ProductsLine from "../components/ProductsLine"
import ProductsDivider from "../components/ProductsDivider"
import { GlobalStateContext } from "../components/context/GlobalContextProvider"

const divider = {
  pt: "Nossos produtos",
  en: "Our products",
  es: "Nuestros productos",
}

const IndexPage = () => {
  const { language } = useContext(GlobalStateContext)
  return (
    <Layout>
      <SEO title="Nossos produtos" />
      <Header />
      <ProductsDivider content={divider[language]} />
      <ProductsLine />
      <Divider content="Bello Fruto" />
    </Layout>
  )
}

export default IndexPage
