import React from "react"
import { Parallax } from "react-parallax"

import * as S from "./styles"

const ProductsDivider = ({ content }) => (
  <Parallax
    blur={0}
    bgImage={require("../../images/acai-bello-fruto-bg-produtos.jpg")}
    bgImageAlt="Açaí Bello Fruto"
    strength={200}
    style={{ marginTop: "94px", padding: "48px 0" }}
  >
    <div>
      <S.Title>{content}</S.Title>
    </div>
  </Parallax>
)

export default ProductsDivider
