import React, { useContext } from "react"
import PolpaDaTriboMedio1kg from "./images/polpaDaTriboMedio1kg"
import PolpaDaTriboPopular1kg from "./images/polpaDaTriboPopular1kg"
import PolpaEspecial100g from "./images/polpaEspecial100g"
import PolpaEspecial1kg from "./images/polpaEspecial1kg"
import PoteComBanana500g from "./images/poteComBanana500g"
import PoteGranolaComBanana220g from "./images/poteGranolaComBanana220g"
import PoteComMorango500g from "./images/poteComMorango500g"
import PoteTradicional500g from "./images/poteTradicional500g"
import PoteGranolaComMorango220g from "./images/poteGranolaComMorango220g"
import PoteGranolaTradicional220g from "./images/poteGranolaTradicional220g"
import PolpaMedio1kg from "./images/polpaMedio1kg"
import PolpaMedio100g from "./images/polpaMedio100g"
import PolpaOrganicoEspecial1kg from "./images/polpaOrganicoEspecial1kg"
import PolpaOrganicoEspecial100g from "./images/polpaOrganicoEspecial100g"
import PolpaOrganicoMedio1kg from "./images/polpaOrganicoMedio1kg"
import PolpaOrganicoMedio100g from "./images/polpaOrganicoMedio100g"
import PolpaPopular1kg from "./images/polpaPopular1kg"
import Pote2kg from "./images/pote2kg"
import Pote5kg from "./images/pote5kg"
import Pote7kg from "./images/pote7kg"
import Pote10kg from "./images/pote10kg"

import { GlobalStateContext } from "../context/GlobalContextProvider"

import * as S from "./styles"

const pote = {
  pt: "Pote",
  en: "Pot",
  es: "Pote",
}

const com = {
  pt: "com",
  en: "with",
  es: "con",
}

const acComBanana = {
  pt: "Açaí com banana",
  en: "Açaí with banana",
  es: "Açaí con banana",
}

const acComMorango = {
  pt: "Açaí com morango",
  en: "Açaí with strawberry",
  es: "Açaí con fresa",
}

const acTradicional = {
  pt: "Açaí tradicional",
  en: "Traditional açaí",
  es: "Açaí tradicional",
}

const polpa = {
  pt: "Polpa",
  en: "Bar",
  es: "Barra",
}

const acEspecial = {
  pt: "Açaí especial",
  en: "Special Açaí",
  es: "Açaí especial",
}

const acMedio = {
  pt: "Açaí médio",
  en: "Medium açai",
  es: "Açaí medio",
}

const acPopular = {
  pt: "Açaí popular",
  en: "Popular açai",
  es: "Açaí popular",
}

const acOrgEspecial = {
  pt: "Açaí orgânico especial",
  en: "Special organic Açaí",
  es: "Açaí orgánico especial",
}

const acOrgMedio = {
  pt: "Açaí orgânico médio",
  en: "Medium organic Açaí",
  es: "Açaí orgánico medio",
}

const medio = {
  pt: "médio",
  en: "medium",
  es: "medio",
}

const ProductsLine = () => {
  const { language } = useContext(GlobalStateContext)
  return (
    <>
      <S.Line>
        <S.ProductsWrapper>
          <S.ProductWrapper className="pote-granola">
            <PoteGranolaComBanana220g />
            <h3>
              {pote[language]} {com[language]} granola 220g
            </h3>
            <h4>{acComBanana[language]}</h4>
          </S.ProductWrapper>
          <S.ProductWrapper className="pote-granola">
            <PoteGranolaComMorango220g />
            <h3>
              {pote[language]} {com[language]} granola 220g
            </h3>
            <h4>{acComMorango[language]}</h4>
          </S.ProductWrapper>
          <S.ProductWrapper className="pote-granola">
            <PoteGranolaTradicional220g />
            <h3>
              {pote[language]} {com[language]} granola 220g
            </h3>
            <h4>{acTradicional[language]}</h4>
          </S.ProductWrapper>
        </S.ProductsWrapper>
      </S.Line>
      <S.Line>
        <S.ProductsWrapper>
          <S.ProductWrapper className="pote-500">
            <PoteComBanana500g />
            <h3>{pote[language]} 500g</h3>
            <h4>{acComBanana[language]}</h4>
          </S.ProductWrapper>
          <S.ProductWrapper className="pote-500">
            <PoteComMorango500g />
            <h3>{pote[language]} 500g</h3>
            <h4>{acComMorango[language]}</h4>
          </S.ProductWrapper>
          <S.ProductWrapper className="pote-500">
            <PoteTradicional500g />
            <h3>{pote[language]} 500g</h3>
            <h4>{acTradicional[language]}</h4>
          </S.ProductWrapper>
        </S.ProductsWrapper>
      </S.Line>
      <S.Line>
        <S.ProductsWrapper>
          <S.ProductWrapper className="pote-grande">
            <Pote10kg />
            <h3>{pote[language]} 10kg</h3>
          </S.ProductWrapper>
          <S.ProductWrapper className="pote-grande">
            <Pote7kg />
            <h3>{pote[language]} 7kg</h3>
          </S.ProductWrapper>
          <S.ProductWrapper className="pote-grande">
            <Pote5kg />
            <h3>{pote[language]} 5kg</h3>
          </S.ProductWrapper>
          <S.ProductWrapper className="pote-grande">
            <Pote2kg />
            <h3>{pote[language]} 2kg</h3>
          </S.ProductWrapper>
        </S.ProductsWrapper>
      </S.Line>
      <S.Line>
        <S.ProductsWrapper>
          <S.ProductWrapper className="polpa-grande">
            <PolpaEspecial1kg />
            <h3>{polpa[language]} 1kg</h3>
            <h4>{acEspecial[language]}</h4>
          </S.ProductWrapper>
          <S.ProductWrapper className="polpa-grande">
            <PolpaMedio1kg />
            <h3>{polpa[language]} 1kg</h3>
            <h4>{acMedio[language]}</h4>
          </S.ProductWrapper>
          <S.ProductWrapper className="polpa-grande">
            <PolpaPopular1kg />
            <h3>{polpa[language]} 1kg</h3>
            <h4>{acPopular[language]}</h4>
          </S.ProductWrapper>
        </S.ProductsWrapper>
      </S.Line>
      <S.Line>
        <S.ProductsWrapper>
          <S.ProductWrapper className="polpa-pequena">
            <PolpaEspecial100g />
            <h3>{polpa[language]} 100g</h3>
            <h4>{acEspecial[language]}</h4>
          </S.ProductWrapper>
          <S.ProductWrapper className="polpa-pequena">
            <PolpaMedio100g />
            <h3>{polpa[language]} 100g</h3>
            <h4>{acMedio[language]}</h4>
          </S.ProductWrapper>
        </S.ProductsWrapper>
      </S.Line>
      <S.Line>
        <S.ProductsWrapper>
          <S.ProductWrapper className="polpa-grande">
            <PolpaOrganicoEspecial1kg />
            <h3>{polpa[language]} 1kg</h3>
            <h4>{acOrgEspecial[language]}</h4>
          </S.ProductWrapper>
          <S.ProductWrapper className="polpa-pequena">
            <PolpaOrganicoEspecial100g />
            <h3>{polpa[language]} 100g</h3>
            <h4>{acOrgEspecial[language]}</h4>
          </S.ProductWrapper>
          <S.ProductWrapper className="polpa-grande">
            <PolpaOrganicoMedio1kg />
            <h3>{polpa[language]} 1kg</h3>
            <h4>{acOrgMedio[language]}</h4>
          </S.ProductWrapper>
          <S.ProductWrapper className="polpa-pequena">
            <PolpaOrganicoMedio100g />
            <h3>{polpa[language]} 100g</h3>
            <h4>{acOrgMedio[language]}</h4>
          </S.ProductWrapper>
        </S.ProductsWrapper>
      </S.Line>
      <S.Line>
        <S.ProductsWrapper>
          <S.ProductWrapper className="polpa-grande">
            <PolpaDaTriboMedio1kg />
            <h3>{polpa[language]} 1kg</h3>
            <h4>Açaí da Tribo {medio[language]}</h4>
          </S.ProductWrapper>
          <S.ProductWrapper className="polpa-grande">
            <PolpaDaTriboPopular1kg />
            <h3>{polpa[language]} 1kg</h3>
            <h4>Açaí da Tribo popular</h4>
          </S.ProductWrapper>
        </S.ProductsWrapper>
      </S.Line>
    </>
  )
}

export default ProductsLine
